import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    signUnProps,
    updateUserProps,
    passwordUserProps,
    forgotPasswordUserProps,
    resetPasswordUserProps
} from './interfaces/users.interface';

const userServices = createApi({
    reducerPath: 'users-service',
    baseQuery: baseQuery,
    tagTypes: ['User', 'Users', 'UsersOptions'],
    endpoints: (builder) => ({
        signup: builder.mutation({
            query: ({ org_id, user_id, ...props }: signUnProps) => ({
                method: 'POST',
                url: `/users/${org_id}/signup/admin/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'Users' }, { type: 'UsersOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/users/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'User' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/users/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Users' }, { type: 'UsersOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/users/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'UsersOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateUserProps) => ({
                method: 'PATCH',
                url: `/users/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        }),
        updatePassword: builder.mutation({
            query: ({ org_id, ...props }: passwordUserProps) => ({
                method: 'PATCH',
                url: `/users/${org_id}/password`,
                body: props
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        }),
        updateMainAdmin: builder.mutation({
            query: ({ org_id, user_id, ...props }: updateUserProps) => ({
                method: 'PATCH',
                url: `/users/${org_id}/update/${user_id}/main-admin`,
                body: props
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        }),
        updateByAdmin: builder.mutation({
            query: ({ org_id, user_id, ...props }: updateUserProps) => ({
                method: 'PATCH',
                url: `/users/${org_id}/update/admin/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        }),
        updatePasswordByAdmin: builder.mutation({
            query: ({ org_id, user_id, ...props }: passwordUserProps) => ({
                method: 'PATCH',
                url: `/users/${org_id}/password/admin/${user_id}`,
                body: props
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        }),
        forgotPassword: builder.mutation({
            query: ({ ...props }: forgotPasswordUserProps) => ({
                method: 'POST',
                url: `/users/forgot-password`,
                body: props
            })
        }),
        resetPassword: builder.mutation({
            query: ({ ...props }: resetPasswordUserProps) => ({
                method: 'POST',
                url: `/users/reset-password`,
                body: props
            })
        }),
        delete: builder.mutation({
            query: ({ org_id, user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/users/${org_id}/delete/${user_id}`,
                body: { user_id, ...props }
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        })
    })
});

export default userServices;
