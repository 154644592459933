import React from 'react';
import editorUtils from '../../utils/editor';
import CircularProgress from '../progress/circular';

interface Props {
    json: any;
    setDownloadImage?: (image: string) => void;
}

export default function CanvasPreview({ json, setDownloadImage }: Props) {
    const [loading, setLoading] = React.useState(true);
    const [image, setImage] = React.useState('');

    React.useEffect(() => {
        if (json) {
            (async () => {
                const img: any = await editorUtils.toImage(json);

                if (img) {
                    setImage(img);
                    if (setDownloadImage) setDownloadImage(img);

                    setTimeout(() => {
                        setLoading(false);
                    }, 100);
                }
            })();
        }
    }, [json]);

    return (
        <div className="canvas-preview">
            {loading ? (
                <div className="loader">
                    <CircularProgress />
                    creating image
                </div>
            ) : (
                <img className="image" src={image} alt="Canvas Preview" />
            )}
        </div>
    );
}
