import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../..';
import Button from '../../../components/custom/button';
import IconButton from '../../../components/custom/icon-button';
import Icon from '../../../components/icon';
import CanvasPreview from '../../../components/preview/canvas';
import ModelPreview from '../../../components/preview/model';
import CircularProgress from '../../../components/progress/circular';
import { modalActions } from '../../../redux/reducers/modal';
import { notifyActions } from '../../../redux/reducers/notify';
import rewardElementServices from '../../../services/reward-element.services';
import { jsPDF } from 'jspdf';
import rewardServices from '../../../services/reward.services';

export default function PreviewRewardElement() {
    const dispatch = useDispatch();
    const { modalProps } = useSelector((state: any) => state.modal);
    const { user: authUser } = useSelector((state: any) => state.user);
    const props = modalProps[modalTypes.PREVIEW_REWARD_ELEMENT];

    const [json, setJson] = React.useState({});
    const [image, setImage] = React.useState('');

    /* query */
    const {
        data: reward,
        error: rewardError,
        isError: isRewardError,
        isLoading: isRewardLoading
    }: any = rewardServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: props?.reward_id
        },
        { skip: !props?.reward_id || !authUser?.organization_id }
    );

    const {
        data: element,
        error: elementError,
        isError: isElementError,
        isLoading: isElementLoading
    }: any = rewardElementServices.useGetQuery(
        {
            org_id: authUser?.organization_id,
            type: 'id',
            value: props?.id
        },
        { skip: !props?.id || !authUser?.organization_id }
    );

    const [
        generate,
        { error: generateElementError, isError: isGenerateElementError, isLoading: isGenerating }
    ]: any = rewardElementServices.useGenerateMutation();

    /* error handling */
    React.useEffect(() => {
        if (isElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: elementError.data?.message
                })
            );
        }
        if (isGenerateElementError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: generateElementError.data?.message
                })
            );
        }
        if (isRewardError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: rewardError.data?.message
                })
            );
        }
    }, [
        elementError,
        isElementError,
        generateElementError,
        isGenerateElementError,
        rewardError,
        isRewardError
    ]);

    React.useEffect(() => {
        if (element?.data?.name)
            dispatch(
                modalActions.update({
                    type: modalTypes.PREVIEW_REWARD_ELEMENT,
                    props: { ...props, title: element?.data?.name }
                })
            );

        if (element?.data?.reward) {
            generateElement();
        }
    }, [element]);

    /* functions */
    const generateElement = async () => {
        if (!props?.reward_id || element?.data?.type === 'MODEL') {
            setJson(element?.data?.reward);
            return;
        }

        const result = await generate({
            reward_id: props?.reward_id,
            element_id: element?.data?.id
        });

        if (result?.data?.data) setJson(result?.data?.data);
    };

    const onBack = () => {
        if (!props?.back_modal?.type) return;

        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: props?.back_modal?.type,
                props: props?.back_modal?.props
            })
        );
    };

    const onDownloadImage = () => {
        let exportName = element?.data?.name || 'reward-element-design';

        const pdf = new jsPDF({
            unit: 'px'
        });

        const imgProps = pdf.getImageProperties(image);
        pdf.internal.pageSize.width = imgProps.width;
        pdf.internal.pageSize.height = imgProps.height;

        pdf.addImage(image, 'png', 0, 0, imgProps.width, imgProps.height);
        pdf.save(`${exportName}.pdf`);
    };

    return (
        <div className="modal-container reward-element-preview-modal">
            <div className="modal-content">
                <div className="top-wrapper">
                    {props?.back_modal && (
                        <Button
                            variant="text"
                            onClick={onBack}
                            className="back"
                            startIcon={<Icon className="icon">chevron_left</Icon>}>
                            Back
                        </Button>
                    )}

                    {element?.data?.type !== 'MODEL' && reward?.data?.status === 'REGISTERED' && (
                        <IconButton onClick={onDownloadImage} tooltip={{ title: 'Download image' }}>
                            <Icon className="txt-theme">download</Icon>
                        </IconButton>
                    )}
                </div>

                {isElementLoading || isGenerating ? (
                    <div className="loader">
                        <CircularProgress />
                        loading element
                    </div>
                ) : element?.data?.type === 'MODEL' ? (
                    <ModelPreview json={json} />
                ) : (
                    <CanvasPreview json={json} setDownloadImage={setImage} />
                )}
            </div>
        </div>
    );
}
