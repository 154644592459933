import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createActivityBatchProps,
    updateActivityBatchProps,
    removeActivityBatchUserProps,
    getActivityBatchProps
} from './interfaces/activity-batch.interface';

const activityBatchServices = createApi({
    reducerPath: 'activity-batch-service',
    baseQuery: baseQuery,
    tagTypes: ['ActivityBatch', 'ActivityBatches', 'ActivityBatchOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, ...props }: createActivityBatchProps) => ({
                method: 'POST',
                url: `/activity-batches/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'ActivityBatches' }, { type: 'ActivityBatchOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/activity-batches/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'ActivityBatch' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/activity-batches/${org_id}/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'ActivityBatches' }, { type: 'ActivityBatchOptions' }]
        }),
        getUserBatches: builder.query({
            query: ({ org_id, page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/activity-batches/${org_id}/user/batches/${page}`,
                body: props
            }),
            providesTags: [{ type: 'ActivityBatches' }, { type: 'ActivityBatchOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/activity-batches/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'ActivityBatchOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateActivityBatchProps) => ({
                method: 'PUT',
                url: `/activity-batches/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [
                { type: 'ActivityBatches' },
                { type: 'ActivityBatchOptions' },
                { type: 'ActivityBatch' }
            ]
        }),
        getBatches: builder.query({
            query: ({ org_id, ...props }: getActivityBatchProps) => ({
                method: 'POST',
                url: `/activity-batches/${org_id}/batches`,
                body: props
            }),
            providesTags: [
                { type: 'ActivityBatches' },
                { type: 'ActivityBatchOptions' },
                { type: 'ActivityBatch' }
            ]
        }),
        removeUser: builder.mutation({
            query: ({ org_id, ...props }: removeActivityBatchUserProps) => ({
                method: 'DELETE',
                url: `/activity-batches/${org_id}/delete/user`,
                body: props
            }),
            invalidatesTags: [
                { type: 'ActivityBatches' },
                { type: 'ActivityBatchOptions' },
                { type: 'ActivityBatch' }
            ]
        }),
        delete: builder.mutation({
            query: ({ org_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/activity-batches/${org_id}/delete`,
                body: props
            }),
            invalidatesTags: [
                { type: 'ActivityBatches' },
                { type: 'ActivityBatchOptions' },
                { type: 'ActivityBatch' }
            ]
        })
    })
});

export default activityBatchServices;
