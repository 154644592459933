import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps,
    searchProps
} from './interfaces/common.interface';
import { createActivityProps, updateActivityProps } from './interfaces/activity.interface';

const activityServices = createApi({
    reducerPath: 'activities-service',
    baseQuery: baseQuery,
    tagTypes: ['Activity', 'Activities', 'ActivitiesOptions', 'Analytics'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ org_id, ...props }: createActivityProps) => ({
                method: 'POST',
                url: `/activities/${org_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'Activities' }, { type: 'ActivitiesOptions' }]
        }),
        get: builder.query({
            query: ({ org_id, type, value }: getProps) => ({
                method: 'POST',
                url: `/activities/${org_id}/get/${type}/${value}`
            }),
            providesTags: [{ type: 'Activity' }]
        }),
        getAll: builder.query({
            query: ({ org_id, page, limit, find = {} }: searchProps) => ({
                method: 'GET',
                url: `/activities/${org_id}/all/${page}`,
                params: {
                    limit,
                    ...find
                }
            }),
            providesTags: [{ type: 'Activities' }, { type: 'ActivitiesOptions' }]
        }),
        analytics: builder.query({
            query: ({ org_id, page, limit, find }: searchProps) => ({
                method: 'GET',
                url: `/activities/${org_id}/analytics/${page}`,
                params: {
                    limit,
                    ...find
                }
            }),
            providesTags: [{ type: 'Analytics' }, { type: 'ActivitiesOptions' }]
        }),
        options: builder.query({
            query: ({ org_id, ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/activities/${org_id}/options`,
                body: props
            }),
            providesTags: [{ type: 'ActivitiesOptions' }]
        }),
        update: builder.mutation({
            query: ({ org_id, ...props }: updateActivityProps) => ({
                method: 'PATCH',
                url: `/activities/${org_id}/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'Activities' }, { type: 'ActivitiesOptions' }]
        }),
        delete: builder.mutation({
            query: ({ org_id, user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/activities/${org_id}/delete/${user_id}`,
                body: { user_id, ...props }
            }),
            invalidatesTags: [{ type: 'Activities' }, { type: 'ActivitiesOptions' }]
        })
    })
});

export default activityServices;
