import React from 'react';
import * as Yup from 'yup';
import Form from '../../../components/form';
import Button from '../../../components/custom/button';
import SubmitButton from '../../../components/form/submit-button';
import TextField from '../../../components/form/text-field';
import { DialogActions } from '@mui/material';
import SearchSelect from '../../../components/form/search-select';
import userUtils from '../../../utils/users';
import { modalActions } from '../../../redux/reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import userServices from '../../../services/user.services';
import { notifyActions } from '../../../redux/reducers/notify';
import { modalTypes } from '../..';

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    role: Yup.string().required('Role is required'),
    email: Yup.string().email().required('Email is required'),
    confirm_email: Yup.string()
        .oneOf([Yup.ref('email'), null], 'Emails must match')
        .required('Confirm email is required'),
    phone: Yup.string()
});

export default function AddUser() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);
    const { modalProps } = useSelector((state: any) => state.modal);
    const { data } = modalProps[modalTypes.ADD_USER];

    /* mutation */
    const [signup, { error: signupError, isError: isSignupError, isLoading: isSigningUp }]: any =
        userServices.useSignupMutation();

    /* error handling */
    React.useEffect(() => {
        if (isSignupError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: signupError.data?.message
                })
            );
        }
    }, [signupError, isSignupError]);

    /* data handling */
    const initialValues = React.useMemo(() => {
        const template = {
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            confirm_email: '',
            role: 'ORG_USER',
            phone: ''
        };

        if (data?.role) template.role = data.role;

        return template;
    }, [data]);

    /* functions */
    const getUserRoles = (): any => {
        return Object.entries(userUtils.types)
            .map(([key, value]) => {
                if (key !== 'SUPER_ADMIN' && key !== authUser.role)
                    return { value: key, label: value };
            })
            .filter((item) => item);
    };

    const onSubmit = async (values: any) => {
        const { confirm_email, ...props } = values;

        const result = await signup({
            ...props,
            password: 'password',
            org_id: data?.organization_id || authUser.organization_id,
            user_id: authUser.id,
            invitee_id: authUser.id
        });

        if (result?.data?.status === 'success') {
            dispatch(
                notifyActions.open({
                    type: 'success',
                    message: result.data?.message
                })
            );

            dispatch(modalActions.hide());
        }
    };

    return (
        <div className="modal-container user-add-modal">
            <Form
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="form">
                <div className="form-content auto-padding">
                    <div className="double-wrap">
                        <TextField name="first_name" placeholder="First Name" label="First Name" />
                        <TextField name="last_name" placeholder="Last Name" label="Last Name" />
                    </div>
                    <TextField name="username" placeholder="Username" label="Username" />
                    {!data?.hideRole && (
                        <SearchSelect
                            name="role"
                            label="Role"
                            placeholder="Role"
                            data={getUserRoles()}
                        />
                    )}
                    <TextField
                        name="email"
                        type="email"
                        placeholder="Email"
                        label="Email"
                        required
                    />
                    <TextField
                        name="confirm_email"
                        type="email"
                        placeholder="Confirm Email"
                        label="Confirm Email"
                        required
                    />
                    <TextField name="phone" placeholder="Phone" label="Phone" />
                </div>

                <DialogActions className="modal-buttons">
                    <Button disabled={isSigningUp} onClick={() => dispatch(modalActions.hide())}>
                        Cancel
                    </Button>
                    <SubmitButton loading={isSigningUp} variant="contained">
                        Invite user
                    </SubmitButton>
                </DialogActions>
            </Form>
        </div>
    );
}
