import React from 'react';
import Button from '../../../components/custom/button';
import { DialogActions } from '@mui/material';
import ImageUpload from '../../../components/image-upload';
import { modalActions } from '../../../redux/reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalTypes } from '../../';
import { notifyActions } from '../../../redux/reducers/notify';
import Skeleton from '../../../components/custom/skeleton';
import userUtils from '../../../utils/users';
import userServices from '../../../services/user.services';

export default function Profile() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: any) => state.user);

    const [image, setImage] = React.useState('');

    /* query */
    const {
        data: user,
        error: userProfileError,
        isError: isUserProfileError,
        isLoading: isUserProfileLoading
    }: any = userServices.useGetQuery(
        {
            org_id: authUser?.role === 'SUPER_ADMIN' ? '0' : authUser?.organization_id,
            type: 'id',
            value: authUser?.id
        },
        { skip: !authUser?.id }
    );

    /* error handling */
    React.useEffect(() => {
        if (isUserProfileError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: userProfileError.data?.message
                })
            );
        }
    }, [userProfileError, isUserProfileError]);

    /* data handling */
    React.useEffect(() => {
        if (user?.data?.avatar) setImage(user.data.avatar);
    }, [user]);

    /* functions */
    const onImage = (event: any, image: string) => {
        setImage(image);
    };

    const onEdit = () => {
        dispatch(modalActions.hide());

        dispatch(
            modalActions.show({
                type: modalTypes.EDIT_PROFILE,
                props: { data: user?.data }
            })
        );
    };

    return (
        <div className="modal-container user-modal">
            <div className="modal-content">
                <div className="image-section">
                    <ImageUpload
                        className="profile-image"
                        image={image}
                        onChange={onImage}
                        preview
                        previewIcon="mood"
                    />
                </div>

                <div className="item-wrap">
                    <p className="title">First Name</p>
                    <p className="value">
                        <Skeleton loading={isUserProfileLoading} height={20}>
                            {user?.data?.first_name || '-'}
                        </Skeleton>
                    </p>
                </div>
                <div className="item-wrap">
                    <p className="title">Last Name</p>
                    <p className="value">
                        <Skeleton loading={isUserProfileLoading} height={20}>
                            {user?.data?.last_name || '-'}
                        </Skeleton>
                    </p>
                </div>
                <div className="item-wrap">
                    <p className="title">Username</p>
                    <p className="value">
                        <Skeleton loading={isUserProfileLoading} height={20}>
                            {user?.data?.username || '-'}
                        </Skeleton>
                    </p>
                </div>
                <div className="item-wrap">
                    <p className="title">Email</p>
                    <p className="value">
                        <Skeleton loading={isUserProfileLoading} height={20}>
                            {user?.data?.email || '-'}
                        </Skeleton>
                    </p>
                </div>
                <div className="item-wrap">
                    <p className="title">Role</p>
                    <p className="value">
                        <Skeleton loading={isUserProfileLoading} height={20}>
                            {userUtils.types[user?.data?.organizations?.[0]?.role] || '-'}
                        </Skeleton>
                    </p>
                </div>
                <div className="item-wrap">
                    <p className="title">State</p>
                    <p className="value">
                        <Skeleton loading={isUserProfileLoading} height={20}>
                            {userUtils.states[user?.data?.organizations?.[0]?.state] || '-'}
                        </Skeleton>
                    </p>
                </div>
            </div>

            <DialogActions className="modal-buttons">
                <Button variant="contained" onClick={onEdit}>
                    Edit profile
                </Button>
            </DialogActions>
        </div>
    );
}
