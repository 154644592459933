const {
    REACT_APP_NODE_ENV,
    REACT_APP_ORG_API_URL,
    REACT_APP_ZEDEID_API_URL,
    REACT_APP_ORG_APP_URL,
    REACT_APP_REWARDS_APP_URL,
    REACT_APP_VIEW_APP_URL
} = process.env;

const config = {
    node_env: REACT_APP_NODE_ENV as string,
    client_urls: {
        org_app:
            REACT_APP_NODE_ENV === 'DEV'
                ? formatUrl(REACT_APP_ORG_APP_URL)
                : 'https://www.v1.mrorgapp.zedeid.com/',
        rewards_app:
            REACT_APP_NODE_ENV === 'DEV'
                ? formatUrl(REACT_APP_REWARDS_APP_URL)
                : 'https://www.v1.mrwalletapp.zedeid.com/',
        view_app:
            REACT_APP_NODE_ENV === 'DEV'
                ? formatUrl(REACT_APP_VIEW_APP_URL)
                : 'https://www.v1.mrview.zedeid.com/'
    },
    server_urls: {
        mr_org_api:
            REACT_APP_NODE_ENV === 'DEV'
                ? formatUrl(REACT_APP_ORG_API_URL)
                : 'https://www.v1.mrorgapi.zedeid.com/api/v1/',
        zedeid_api:
            REACT_APP_NODE_ENV === 'DEV'
                ? formatUrl(REACT_APP_ZEDEID_API_URL)
                : 'https://www.offchaindids.zedeid.com/v2/'
    }
};

function formatUrl(url: any) {
    if (!url) return '';

    return url.slice(-1) === '/' ? url : url + '/';
}

export default config;
